import React from 'react'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import Layout from '../layout/layout'
import { Link } from 'gatsby'
import Spinner from '../components/spinner';
import SEO from "../layout/seo"
import HubspotForm from 'react-hubspot-form'
import './styles/register.scss'

const pageContent = (<>
        
        <div style={{
            minHeight: `470px`
        }}>
            <HubspotForm
                portalId="23545599"
                formId='fe001b3b-f9f4-4db3-b71d-e023ece2a3bb'
                loading={<Spinner />}
                sfdcCampaignId="7013o000002CiPqAAK"
            />
        </div>

    </>)


const register = () => {
    
    
    return (

        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <>
                    { modal ? (
                        <main><Link className="closeIcon" to={closeTo}><span>Close</span></Link>
                            <div id="register-page">
                                <h2 id="register-page-title">Ignite Summit 2025<br/><span className="turquoise">Watch on Demand</span></h2>
                                {pageContent}
                            </div>
                        </main>
                    ) : (
                        <Layout attr={"page register"}>
                            <SEO title="Ignite Summit 2025" description="Watch on Demand" />
                            <div class="ReactModal__Content">
                                <div className="container register-page" id="register-page">
                                    <h2 id="register-page-title">Ignite Summit 2025<br/><span className="turquoise">Watch on Demand</span></h2>
                                    {pageContent}
                                </div>
                            </div>
                        </Layout>
                    )}
                </>
            )}
            
        </ModalRoutingContext.Consumer>
    )
}

export default register
